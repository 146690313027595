import React, {Component} from 'react';
import {Alert, Paper, Snackbar, Tooltip, Unstable_Grid2 as Grid} from '@mui/material';
import {
  DataGridPro,
  getGridNumericOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef, GridEventLookup,
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {Prisma} from '@prisma/client';
import _ from 'lodash';
import './PositionsTable.scss';
import {getPositions, patchBundleOrderedPartConfig} from '../../services/knestKontrol';
import {formatCurrency, generateOrderByFromSortModel, generateWhereFromFilterModel, toDisplayDate, toDisplayDatetime} from '../../utils/formatHelpers';
import {KaktusStatuses, OrderStatuses} from '../../types/ReferentialData';
import {GridCallbackDetails} from '@mui/x-data-grid/models/api';
import {Visibility} from '@mui/icons-material';
import {NavLink} from 'react-router-dom';

type Position = Prisma.BundleOrderedPartConfigTeaserGetPayload<{
  include: {
    shipmentBundleOrderedPartConfigs: true,
    orderedPartConfigTeaser: {
      include: {
        orderedPart: true,
        orderedPartListTeaser: true,
      }
    },
    bundle: true,
  }
}>

type Props = {};

type State = {
  loading: boolean,
  positions: Position[],
  totalItemCount: number,
  nextPage: number,
  itemsPerPage: number,
  snackbarOpen: boolean
  snackbarSeverity: 'success' | 'error' | 'warning' | 'info'
  snackbarMessage: string
  sortModel: GridSortModel,
  filterModel: GridFilterModel,
  backendFilter: Prisma.BundleOrderedPartConfigTeaserWhereInput,
  selectedRows: GridRowSelectionModel,
};

class PositionsTable extends Component<Props, State> {
  columns: GridColDef<Position>[];
  columnTypes: { [key: string]: string } = {};

  constructor(props) {
    super(props);

    this.columns = [
      {
        field: 'id',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        headerName: '#', width: 100,
        sortable: false,
        valueFormatter: (value: number) => value.toString(),
        filterOperators: getGridNumericOperators().filter(({value}) => !['isEmpty', 'isNotEmpty'].includes(value)),
      },
      {field: 'bundle.bundleKid', type: 'string', headerName: 'Bundle ID', width: 150, sortable: false, valueGetter: (_value, row) => row.bundle?.bundleKid},
      {field: 'orderedPartConfigTeaser.orderedPart.name', type: 'string', headerName: 'Part Name', width: 200, valueGetter: (_value, row) => row.orderedPartConfigTeaser?.orderedPart?.name},
      {field: 'orderedPartConfigTeaser.orderedPart.drawingNr', type: 'string', headerName: 'Drawing Nr.', width: 200, valueGetter: (_value, row) => row.orderedPartConfigTeaser?.orderedPart?.drawingNr},
      {field: 'bundle.companyName', type: 'string', headerName: 'Supplier Name', width: 250, valueGetter: (_value, row) => row.bundle?.companyName},
      {field: 'bundle.currentDeliveryDate', type: 'date', headerName: 'Bundle Delivery Date', width: 200, valueFormatter: (_value, row) => toDisplayDate(row.bundle?.currentDeliveryDate!)},
      {
        field: 'orderedPartConfigTeaser.orderedPartListId', type: 'number', headerName: 'Order ID', width: 150, align: 'left', headerAlign: 'left',
        valueGetter: (_value, row) => `KMS-${row.orderedPartConfigTeaser?.orderedPartListId}`,
        renderCell: (params) => {
          const orderId = params.row.orderedPartConfigTeaser?.orderedPartListId as number;
          const filterModel = JSON.stringify({items: [{field: 'kmsId', operator: 'contains', value: orderId.toString()}]});
          return <NavLink to={`/orders#editOrderId=${orderId}&filterModel=${encodeURIComponent(filterModel)}`}>{`KMS-${orderId}`}</NavLink>;
        },
      },
      {
        field: 'orderedPartConfigTeaser.orderedPartListTeaser.name', type: 'string', headerName: 'Order Name', width: 200,
        valueGetter: (_value, row) => row.orderedPartConfigTeaser?.orderedPartListTeaser?.name,
      },
      {
        field: 'orderedPartConfigTeaser.orderedPartListTeaser.internalOrderNumber', type: 'string', headerName: 'Customer Order Nr.', width: 200,
        valueGetter: (_value, row) => row.orderedPartConfigTeaser?.orderedPartListTeaser?.internalOrderNumber,
      },
      {field: 'orderedPartConfigTeaser.orderedPartListTeaser.companyName', headerName: 'Customer Name', width: 200, valueGetter: (_value, row) => row.orderedPartConfigTeaser?.orderedPartListTeaser?.companyName},
      {
        field: 'orderedPartConfigTeaser.orderedPartListTeaser.currentDeliveryDate', type: 'date', headerName: 'Customer Delivery Date', width: 200,
        valueFormatter: (_value, row) => toDisplayDate(row.orderedPartConfigTeaser?.orderedPartListTeaser?.currentDeliveryDate!),
      },
      {field: 'pricePerPiece', type: 'number', headerName: 'Supplier price/pc.', width: 150, valueFormatter: (value) => formatCurrency(value)},
      {field: 'orderedPartConfigTeaser.totalCostPerUnit', type: 'number', headerName: 'Customer price/pc.', width: 150, valueGetter: (_value, row) => row.orderedPartConfigTeaser?.totalCostPerUnit, valueFormatter: (value) => formatCurrency(value)},
      {field: 'orderedPartConfigTeaser.batchSize', type: 'number', headerName: 'Qty.', width: 100, valueGetter: (_value, row) => row.orderedPartConfigTeaser?.batchSize},
      {field: 'weightInKg', type: 'number', headerName: 'Weight', width: 150, valueFormatter: (value) => value ? `${value} kg` : ''},
      {field: 'statusKey', type: 'singleSelect', headerName: 'Supplier Pos. Status', width: 200, valueOptions: KaktusStatuses.map(s => ({value: s.statusKey, label: s.translation}))},
      {field: 'bundle.statusKey', type: 'singleSelect', headerName: 'Bundle Status', width: 200, valueGetter: (_value, row) => row.bundle?.statusKey, valueOptions: KaktusStatuses.map(s => ({value: s.statusKey, label: s.translation}))},
      {
        field: 'orderedPartConfigTeaser.statusKey', type: 'singleSelect', headerName: 'Customer Pos. Status', width: 200,
        valueGetter: (_value, row) => row.orderedPartConfigTeaser?.statusKey,
        valueOptions: OrderStatuses.map(s => ({value: s.statusKey, label: s.translation})),
      },
      {
        field: 'orderedPartConfigTeaser.orderedPartListTeaser.statusKey', type: 'singleSelect', headerName: 'Order Status', width: 200,
        valueGetter: (_value, row) => row.orderedPartConfigTeaser?.orderedPartListTeaser?.statusKey,
        valueOptions: OrderStatuses.map(s => ({value: s.statusKey, label: s.translation})),
      },
      {field: 'hubStorageSpot', type: 'string', headerName: 'Hub Storage Spot', width: 200, editable: true},
      {field: 'isLongTermStorage', type: 'boolean', headerName: 'Long Term Storage', width: 150, editable: true},
      {field: 'isInComplaint', type: 'boolean', headerName: 'In Complaint', width: 150, editable: true},
      {field: 'isCheckedForQa', type: 'boolean', headerName: 'Checked for QA', width: 150, editable: true},
      {
        field: 'shipmentBundleOrderedPartConfigs.shipmentId', headerName: 'Related Shipment(s)', width: 150, type: 'number', filterable: false, sortable: false,
        valueFormatter: (_value, row) => row.shipmentBundleOrderedPartConfigs?.map(s => `S-${s.shipmentId}`).join(', '),
      },
      {field: 'createdAt', type: 'dateTime', headerName: 'Created At', width: 220, valueFormatter: (value: string) => toDisplayDatetime(value)},
      {
        field: 'actions', type: 'actions', headerName: 'Actions', width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Tooltip title={'View in Kaktus'}><Visibility /></Tooltip>}
            label={'View in KMS'}
            onClick={() => window.open(`${process.env.REACT_APP_KAKTUS_URL}/bundle-suppliers/${params.row.bundleId}`)}
          />,
        ],
      },
    ];

    this.columnTypes = this.columns.reduce((acc, {field, type}) => {
      acc[field] = type;
      return acc;
    }, {});

    const queryParams = new URLSearchParams(document.location.hash?.substring(1));

    this.state = {
      loading: true,
      positions: [],
      totalItemCount: 0,
      nextPage: 1,
      itemsPerPage: 100,
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      sortModel: JSON.parse(queryParams.get('sortModel') || 'null') as GridSortModel || [],
      filterModel: JSON.parse(queryParams.get('filterModel') || 'null') as GridFilterModel || {logicOperator: GridLogicOperator.And, items: []},
      backendFilter: generateWhereFromFilterModel(
        JSON.parse(queryParams.get('filterModel') || 'null') as GridFilterModel || {logicOperator: GridLogicOperator.And, items: []},
        this.columnTypes,
      ),
      selectedRows: [],
    };
  }

  CustomToolbar = () => {
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //   setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    return (
      <GridToolbarContainer>
        <Grid container justifyContent={'space-between'} width={'100%'}>
          <Grid>
            <GridToolbarColumnsButton />
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Grid>
          {/*<Grid>*/}
          {/*  <Button*/}
          {/*    id={'batch-actions-button'}*/}
          {/*    aria-controls={open ? 'batch-actions-menu' : undefined}*/}
          {/*    aria-expanded={open ? 'true' : undefined}*/}
          {/*    aria-haspopup={'true'}*/}
          {/*    onClick={handleClick}*/}
          {/*    endIcon={<MoreVert />}*/}
          {/*    size={'small'}*/}
          {/*    disabled={!this.state.selectedRows.length}*/}
          {/*  >*/}
          {/*    Batch Actions*/}
          {/*  </Button>*/}
          {/*  <Menu*/}
          {/*    id={'batch-actions-menu'}*/}
          {/*    anchorEl={anchorEl}*/}
          {/*    open={open}*/}
          {/*    onClose={handleClose}*/}
          {/*    MenuListProps={{*/}
          {/*      'aria-labelledby': 'batch-actions-button',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <MenuItem>*/}
          {/*      Update Storage Spot*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem>*/}
          {/*      Mark all as Long Term Storage*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem>*/}
          {/*      Mark all as Not Long Term Storage*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem>*/}
          {/*      Mark all as In Complaint*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem>*/}
          {/*      Mark all as Not In Complaint*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem>*/}
          {/*      Mark all as Checked for QA*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem>*/}
          {/*      Mark all as Not Checked for QA*/}
          {/*    </MenuItem>*/}
          {/*  </Menu>*/}
          {/*</Grid>*/}
        </Grid>
      </GridToolbarContainer>
    );
  };

  async componentDidMount() {
    await this.loadRows();
  }

  loadRows = async () => {
    this.setState({
      loading: true,
    });

    try {
      const {
        page,
        pagination: {totalItemCount},
      } = await getPositions(this.state.nextPage, this.state.itemsPerPage, [...generateOrderByFromSortModel(this.state.sortModel), {id: 'desc'}], {
        shipmentBundleOrderedPartConfigs: true,
        orderedPartConfigTeaser: {
          include: {
            orderedPart: true,
            orderedPartListTeaser: true,
          },
        },
        bundle: true,
      }, this.state.backendFilter);

      this.setState({
        positions: this.state.nextPage === 1 ? page : this.state.positions.concat(page),
        nextPage: this.state.nextPage + 1,
        totalItemCount,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to load positions',
      });
    }

    this.setState({
      loading: false,
    });
  };

  updateDocumentHash = () => {
    document.location.hash = `filterModel=${encodeURIComponent(JSON.stringify(this.state.filterModel))}&sortModel=${encodeURIComponent(JSON.stringify(this.state.sortModel))}`;
  };

  handleSortModelChange = async (gridSortModel: GridSortModel) => {
    if (this.state.sortModel !== gridSortModel) {
      this.setState({
        sortModel: gridSortModel,
        nextPage: 1,
      }, async () => {
        await this.loadRows();
        this.updateDocumentHash();
      });
    }
  };

  handleFilterModelChange = async (filterModel: GridFilterModel, details: GridCallbackDetails<'filter'>) => {
    const newBackendFilter = generateWhereFromFilterModel(filterModel, this.columnTypes);
    const backendFilterChanged = !_.isEqual(this.state.backendFilter, newBackendFilter);

    this.setState({
      nextPage: details.reason && backendFilterChanged ? 1 : this.state.nextPage,
      filterModel,
      backendFilter: newBackendFilter,
    }, async () => {
      (details.reason && backendFilterChanged) && await this.loadRows();
      this.updateDocumentHash();
    });

  };

  handleOnRowsScrollEnd = async (params: GridEventLookup['rowsScrollEnd']['params']) => {
    if (params.visibleRowsCount === this.state.totalItemCount) {
      return;
    }

    await this.loadRows();
  };

  updatePosition = async (newRow: Position, oldRow: Position) => {
    try {
      await patchBundleOrderedPartConfig(newRow.id, {
        hubStorageSpot: newRow.hubStorageSpot?.trim() || null,
        isLongTermStorage: newRow.isLongTermStorage!,
        isInComplaint: newRow.isInComplaint!,
        isCheckedForQa: newRow.isCheckedForQa!,
      });

      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Position updated successfully',
      });

      return newRow;
    } catch (err) {
      console.error(err);
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to update position',
      });
      return oldRow;
    }
  };

  // updatePositions = async (newValues: {
  //   hubStorageSpot?: string,
  //   isLongTermStorage?: boolean,
  //   isInComplaint?: boolean,
  //   isCheckedForQa?: boolean,
  // }) => {
  //   try {
  //     await patchBundleOrderedPartConfigs({
  //       where: {id: {in: this.state.selectedRows as number[]}},
  //       data: {
  //         hubStorageSpot: newValues !== undefined ? newValues.hubStorageSpot?.trim() || null : undefined,
  //         isLongTermStorage: newValues.isLongTermStorage,
  //         isInComplaint: newValues.isInComplaint,
  //         isCheckedForQa: newValues.isCheckedForQa,
  //       },
  //     });
  //
  //     this.setState({
  //       snackbarOpen: true,
  //       snackbarSeverity: 'success',
  //       snackbarMessage: 'Positions updated successfully',
  //     });
  //   } catch (err) {
  //     console.error(err);
  //     this.setState({
  //       snackbarOpen: true,
  //       snackbarSeverity: 'error',
  //       snackbarMessage: 'Failed to update positions',
  //     });
  //   }
  // };

  render() {
    return (
      <Grid container>
        <Grid xs={12}>
          <Paper style={{height: 'calc(100vh - 180px)'}}>
            <DataGridPro<Position>
              columns={this.columns}
              rows={this.state.positions}
              loading={this.state.loading}
              onRowsScrollEnd={this.handleOnRowsScrollEnd}
              rowCount={this.state.totalItemCount}
              slots={{toolbar: this.CustomToolbar}}

              filterMode={'server'}
              filterModel={this.state.filterModel}
              onFilterModelChange={this.handleFilterModelChange}
              filterDebounceMs={1500}
              headerFilters
              headerFilterHeight={80}

              sortingMode={'server'}
              sortModel={this.state.sortModel}
              onSortModelChange={this.handleSortModelChange}

              initialState={{
                density: 'compact',
                sorting: {sortModel: this.state.sortModel},
                filter: {filterModel: this.state.filterModel},
                columns: {columnVisibilityModel: {id: false}},
                pinnedColumns: {left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'id', 'bundle.bundleKid', 'orderedPartConfigTeaser.orderedPart.name'], right: ['actions']},
              }}

              checkboxSelection
              rowSelectionModel={this.state.selectedRows}
              onRowSelectionModelChange={(newSelection) => this.setState({selectedRows: newSelection})}

              processRowUpdate={async (newRow, oldRow) => this.updatePosition(newRow, oldRow)}
              editMode={'row'}
            />
          </Paper>
          <Snackbar
            open={this.state.snackbarOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            autoHideDuration={4000}
            onClose={(_event, reason) => reason !== 'clickaway' && this.setState({snackbarOpen: false})}
          >
            <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    );
  }
}

export default PositionsTable;
